.nextgen-container-style-v2 {
  height: 200px;
  background: #214e67;
  border-bottom: 1px solid #80808042;
}
.nextgen-container-style {
  height: 200px;
  background: white;
  border-bottom: 1px solid #80808042;
}
.text-white {
  color: white !important;
}
.card-purple {
  background-color:rgb(90, 85, 151)
}
.card-aqua {
  background-color:rgb(106, 177, 188)
}
.card-brown {
  background-color:rgb(208, 111, 57)
}
.border-radius-10 {
  border-radius: 10px;
}
.font-weight-300 {
  font-weight:300;
}
.box-shadow {
  box-shadow: 1px 0px 5px 0px rgb(0 0 0 / 20%);
  -webkit-box-shadow: 1px 0px 5px 0px rgb(0 0 0 / 20%);
  -moz-box-shadow: 1px 0px 5px 0px rgb(0 0 0 / 20%);
}
.no-border {
  border: none!important;
}
.hover-animation {
  -webkit-transition: 0.2s ease-out;
  -moz-transition: 0.2s ease-out;
  transition: 0.2s ease-out;
  display:block;
  height:51px;
  width:51px;
  margin:0px auto;
}
.hover-animation:hover {
  -webkit-transform: rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
  -webkit-transition: 0.2s ease-out;
  -moz-transition:  0.2s ease-out;
  transition:  0.2s ease-out;
}
.hover-icon {
  -webkit-transform: rotateZ(90deg);
  -moz-transform: rotateZ(90deg);
  transform: rotateZ(90deg);
}
.unhover-icon {
  -webkit-transform: rotateZ(0deg);
  -moz-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
}
.col-md-grow {
  width:100%;
  transition: width 1s;
}
.col-md-ungrow {
  width:16.66667%;
  transition: width 1s;
}
.col-md-6-ungrow {
  width:50%;
  transition: width 1s;
}
.col-md-hide {
  width:0;
  -webkit-transition: 0.2s ease-out;
  -moz-transition:  0.2s ease-out;
  transition:  0.2s ease-out;
  padding-right:0!important;
  padding-left:0!important;
}
.show-task-panel {
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.5s linear;
}
.hide-task-panel {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.5s, opacity 0.5s linear;
}
.card-hide {
  display:none;
}
.card-unhide {
  display:block;
  -webkit-transition: 0.2s ease-out;
  -moz-transition:  0.2s ease-out;
  transition:  0.2s ease-out;
}
.show-third-static-body-right-card {
  width: 440px;
  /* float: left; */
  -webkit-transition: 0.2s ease-out;
  -moz-transition:  0.2s ease-out;
  transition:  0.2s ease-out;
  position: relative;
  top: 15px;
  right: -20px !important;
  z-index: 1;
  height: 210px;
}
@keyframes col-md-hide {
  0%   { width:16.66667%; }
  100% { width:0;padding-right:0;padding-left:0 }
}
@keyframes col-md-6-hide {
  0%   { width:50%; }
  100% { width:0;padding-right:0;padding-left:0 }
}

.col-md-unhide {
  display:block;
  -webkit-transition: 0.2s ease-out;
  -moz-transition:  0.2s ease-out;
  transition:  0.2s ease-out;
}

.static-body {
  width:168px;
  float:left;

}
.card .card-header {
  background-color: #fff;
}
.static-unbody {
  display:none;
  }
.second-static-body {
  width: 0px;
  display:none;
  animation: width-up 0.5s;
}
.show-second-static-body {
  height: 0px;
  position: absolute;
  /* display: block; */
  top: 4px;
  animation: width-up 0.5s;
  width: 40%;
}
.show-second-static-body-right-card {
  transition: width 1s;
  height:0px;
  z-index: 1;
}
.second-place {
  right: 70px !important;
}
@keyframes width-up {
  0%   { opacity:0; }
  20%  { opacity:0; }
  70%  { opacity:0.7; }
  100% { opacity:1; }
}
.card .card-header:first-child {
  border-top-left-radius: 6px!important;
  border-top-right-radius: 6px!important;
}
.dashboard-wrapper {
  padding-right: 0px;
  padding-left: 51px;
  padding-top: 53px;
  margin-bottom: 15px;
  background-color:#f4f4f4!important;
  z-index: 10;
  position: relative;
}
.list-group-item {
  position: relative;
  display: block;
  padding: 0.50rem 0.50rem;
  color: #e3ebf6;
  text-decoration: none;
  background-color: transparent;
  border: 1px solid #ffffff00;
  border-bottom: 1px solid #ffffff45;
  border-radius:0px!important;
  font-size: 12px;
}
.list-group-item:last-child {
  border: 1px solid #ffffff00;
  border-bottom: 1px solid #ffffff00;
  border-radius:0px!important;
}
@media (max-width:1400px) {
  .static-body {
    width:145px;
    float:left;
  }
  .show-second-static-body-right-card {
    width: 375px;
  }
  .show-second-static-body {
    width: 195px;
    float: right;
    color: white;
    border-left: 1px solid;
    transition: width 1s;
    position: absolute;
    top: 5px;
    left: 180px;
    z-index: 1;
}
}
@media (max-width:1200px) {
  .static-body {
    width:115px;
    float:left;
  }
  .show-second-static-body-right-card {
    width: 305px;
    float: right;
    color: white;
    border-left: 1px solid;
    transition: width 1s;
    position: absolute;
    top: 15px;
    right: 10px !important;
    z-index: 1;
  }
  .show-second-static-body {
    width: 312px;
    float: right;
    color: white;
    border-left: 1px solid;
    transition: width 1s;
    position: absolute;
    top: 5px;
    left: 145px;
    z-index: 1;
}
}
