html {
	-webkit-text-size-adjust: none;
	touch-action: manipulation;
}

body {
  display: block !important;
  background: url("../images/mybg.png") !important;
}
.tpo-background {
  background: url("../images/auth-bg.png") !important;
}
.highlight-table-checked {
  background-color: rgb(211 211 211 / 75%) !important;
  cursor: pointer !important;
}

.product-pricer-class .modal-dialog {
  max-width: 95%;
  width: 95%;
}

.pac-container {
  z-index: 3000 !important;
  min-width: 400px;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 2000 !important;
}

/* p-multiselect styles - begin */
.p-overlay.p-component {
  z-index: 9999 !important; /* updating lib */
}

.ng-trigger-overlayAnimation > .p-multiselect-panel .p-multiselect-items:not(.p-multiselect-virtualscroll) {
  padding: 0 !important;
}

.ng-trigger-overlayAnimation .p-checkbox .p-checkbox-box {
  width: 15px;
  height: 15px;
}

.ng-trigger-overlayAnimation .p-checkbox .p-checkbox-box  .p-checkbox-icon {
  font-size: 10px;
}

.ng-trigger-overlayAnimation .p-component .p-checkbox {
  align-items: center;
}

.ng-trigger-overlayAnimation .p-multiselect-label {
  padding: 0.385rem 0.75rem;
}

.ng-trigger-overlayAnimation .p-multiselect-header {
  padding: 0.385rem 1.25rem;
}

.ng-trigger-overlayAnimation .p-inputtext {
  padding: 0.385rem 0.5rem;
}

.ng-trigger-overlayAnimation.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  padding: 0.385rem 1.25rem;
}

.ng-trigger-overlayAnimation.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.385rem 1.25rem;
  min-width: 15px !important;
  min-height: 15px !important;
}

.ng-trigger-overlayAnimation .p-checkbox .p-checkbox-box  .p-checkbox-icon {
  font-size: 10px !important;
}

.ng-trigger-overlayAnimation .p-component .p-checkbox {
  align-items: center !important;
}

.ng-trigger-overlayAnimation .p-multiselect-label {
  padding: 0.385rem 0.75rem !important;
}

.ng-trigger-overlayAnimation .p-multiselect-header {
  padding: 0.385rem 1.25rem !important;
}

.ng-trigger-overlayAnimation .p-inputtext {
  padding: 0.385rem 0.5rem !important;
}

.ng-trigger-overlayAnimation.p-multiselect-panel .p-multiselect-items .p-multiselect-item-group {
  padding: 0.385rem 1.25rem !important;
}

.ng-trigger-overlayAnimation.p-multiselect-panel .p-multiselect-items .p-multiselect-item {
  padding: 0.385rem 1.25rem !important;
}

.p-multiselect .p-multiselect-label {
  padding: 0.385rem 0.75rem !important;
}

.calculators-container {
  box-sizing: border-box;
  outline: rgb(48, 62, 103) none 0px;
  max-width: 607px !important;
}

/* p-multiselect styles - end */

.mms-image {
  height:200px !important;
  width:200px !important;
  border-radius:5px !important;
  display: inline-block;
}

.loan-docs-task .modal-dialog {
  max-width: 70%;
}

.doc-files-edit .modal-dialog {
  max-width: 95%;
}

.product-view-table-class .modal-dialog {
  max-width: 90%;
  width: 90%;
  min-height: 800px;
}

.is-invalid>.form-control {
  border: 1px solid #ff0000;
}

.p-datatable .p-datatable-tbody>tr.ineligible-product>td {
  color: red;
}

.p-component {
  font-size: 0.8rem !important;
}

.p-datatable .p-datatable-tbody>tr>td {
  text-align: left;
  border: 1px solid #dee2e6;
  border-width: 0 0 1px 0;
  padding: 0.5rem 0.5rem !important;
}

.p-datatable.p-datatable-gridlines .p-datatable-header {
  border-width: 1px 1px 0 1px;
}

.p-input-icon-left>i:first-of-type {
  right: 0.75rem !important;
  color: #6c757d;
}

.p-d-flex {
  display: flex !important;
}

.p-ml-auto {
  margin-left: auto !important;
}

.ineligible-product {
  color: red !important;
}

.table .table-dark th {
  color: white !important;
}

.modal-backdrop:nth-of-type(2) {
  background-color: rgb(0 0 0 / 20%) !important;
  z-index: 1060 !important;
}

.modal-backdrop:nth-of-type(3) {
  background-color: rgb(0 0 0 / 20%) !important;
  z-index: 1060 !important;
}

.modal-backdrop {
  background-color: rgb(0 0 0 / 20%) !important;
  /*Override for FPS issues*/
  backdrop-filter: blur(px) !important;
}

.fade {
  /*Override for FPS issues*/
  transition: opacity 0s linear !important;
}

.left-sidenav-menu {
  padding-left: 0;
  margin-bottom: 0;
  padding: 0px;
}

.table .checkbox label {
  margin-bottom: 8px;
  margin-top: 8px;

}

.delete-button-position .mdi {
  font-size: 25px;
  position: absolute;
  right: -15px;
  z-index: 9999;
  top: -19px;
}

.p-datatable .p-datatable-thead>tr>th {
  padding: 0.5rem 0.5rem !important;
}

.hide {
  display: none;
}

.form-check-inline {
  display: inline-block;
  margin-right: 0rem !important;
}

.borrower-info-checkbox {
  position: relative !important;
  top: -5px !important;
}

.borrower-info-span {
  position: relative !important;
  left: -8px !important;
  top: 4px !important;
}

.btn-outline-secondary {
  border-color: #e3ebf6 !important;
}

.negative-margin-5 {
  margin-bottom: -5px;
}

.navigation-menu>li a {
  padding-right: 4px;
}

.navigation-menu>li a:hover {
  text-decoration: none;
}

.navigation-menu>li a i {
  font-size: 10px;
  margin-right: 1px;
  margin-top: -2px;
}

.navbar-brand {
  padding-top: 0px;
  margin-top: -1px;
}

.select2-container--classic .select2-selection--multiple {
  border: 1px solid #e3ebf6 !important;
}

.submit-container.text-center {
  height: 105px;
  background-color: white;
  position: fixed;
  bottom: 0px;
}

.page-title-box {
  padding: 0px 20px !important;
  width: 10% !important;
  position: relative;
  top: 10px;
  height: 20px;
}
.nextgen {
  height: 53px!important;
  position:fixed!important
}
[data-layout="horizontal"] .topbar {
  position: sticky!important;
  height: 53px;
  border-bottom: 0px solid #e3ebf6;
}

.page-wrapper {
  padding-top: 0px!important;
}

.rightnav .rightnav-activity-container {
  position: relative;
  bottom: -40px;
  background-color: white;
}
.required-field-spacing {
  position: relative;
  top: 11px;
}
@media (max-width: 991px) {
  .topbar .navigation-menu>li>a {
    color: #68728c;
  }
  .navigation-menu>li a i {
    color: #7081b9!important;
    fill: rgba(112,129,185,0.12)!important;
  }
  .topbar .navigation-menu>li .submenu li a {
    display: block;
    position: relative;
    padding: 10px 20px;
    font-size: 13px;
    color: #68728c;
}
}
ngb-modal-window.d-block.modal.modal-fullscreen.fade.show >
div.modal-dialog.modal-dialog-centered.modal-dialog-scrollable.modal-fullscreen.modal-xl >
div.modal-content > loan-pricing-search-dialog.component-host-scrollable {
  height: 100vh!important;
}
@media (min-height: 900px) {
  .rightnav .analytic-dash-activity {
    height: 54vh;
  }
  .rightnav .rightnav-activity-container {
    position: relative;
    bottom: -10px;
    background-color: white;
  }
}
@media (min-height: 1080px) {
  .rightnav .analytic-dash-activity {
    height: 57vh;
  }
}
@media (min-height: 1200px) {
  .rightnav .analytic-dash-activity {
    height: 61vh;
  }
}
@media (min-height: 1440px) {
.rightnav .analytic-dash-activity {
  height: 69vh;
  }
}
@media (min-height: 2160px) {
  .rightnav .analytic-dash-activity {
    height: 75vh;
  }
}

@media (min-height: 900px) {
  .rightnav .task-notes-activity {
    height: 24vh;
  }
  .rightnav .rightnav-activity-container {
    position: relative;
    bottom: -10px;
    background-color: white;
  }
}
@media (min-height: 1080px) {
  .rightnav .task-notes-activity {
    height: 39vh;
  }
}
@media (min-height: 1200px) {
  .rightnav .task-notes-activity {
    height: 42vh;
  }
}
@media (min-height: 1440px) {
.rightnav .task-notes-activity {
  height: 50vh;
  }
}
@media (min-height: 2160px) {
  .rightnav .task-notes-activity {
    height: 70vh;
  }
}

/* .analytic-dash-activity {
  height: 100%!important;
} */
.content-replacer {
  margin-bottom: 20px;
}
.router-link-active:last-child {
  font-weight: 500;
}
.page-wrapper .page-content {
  min-height: calc(100vh - 110px);
  padding: 0 8px 0px 8px!important;
}
/* width */
::-webkit-scrollbar {
  width: 5px !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1 !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2021244a !important;
  border-radius: 2px !important;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(23, 96, 253, 0.199) !important;
}

.navigation-menu>li a i {

  color: unset !important;
}
/*---NextGen Profile Menu---*/
.nextgen-profile-menu {
  position: absolute;
  inset: 0px auto auto 0px;
  margin: 0px;
  transform: translate(-156px, 0px);
}
a.nav-link i {
  font-size: 24px;
  /* color: #00000075 !important; */
  position: relative;
}
.navbar-custom .topbar-nav li {
  line-height: 0.5px;
}
.dropdown-item {
  padding: 6px 12px;
}
.dropdown-item-padding {
  padding: 16px 24px;
}
.classic-dropdown-item-padding {
  padding: 16px 24px!important;
}
.border-radius-10 {
  border-radius:10px;
}
.dropdown-profile {
  width:280px!important;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0,0,0,0.05);
}
.tpo-topbar-icon-mobil {
  display:none;
}
.tpo-report-card > loading-indicator > .full-height > .col-12 > .row > .mx-auto > .card {
  box-shadow: 2px 1px 9px 0px rgb(0 0 0 / 0%);
}
.tpo-report-card > loading-indicator > .full-height > .col-12 > .row > .mx-auto > .card > .card-body > .ex-page-content {
  position: relative;
  top: 15px;
}
#tpo-app-detail-loader > .full-height > .col-12 > .row > .mx-auto > .card {
  box-shadow: 2px 1px 9px 0px rgb(0 0 0 / 0%);
}
.card.tpo-report-card > .card-header {
background-color: #303e67;
}
.tpo-report-card > .card-header > .card-title {
color:white;
font-weight: 500;
}
.tpo-report-card > .card-header > .card-title > i {
  margin-right: 5px;
  font-size: 12px;
}
.tpo-mobile-menu-container-style {
  padding-left: 0px;
  padding-right: 0px;
}
tpo-menu > .left-sidenav.tpo-report-card > .menu-content > .simplebar-wrapper > .simplebar-mask > .simplebar-offset >
.simplebar-content-wrapper > .simplebar-content > ul.metismenu > li.mm-active > ul.nav-second-level > li.nav-item > span.tab-1003-val-icon {
  float: left;
  margin-right: 10px;
  font-size: 16px;
  margin-top: 6px!important;
}
@media (max-width: 991px) {
  .tpo-mobile-menu-style {
    border:1px solid #d3ddeb !important;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-top:1px;
  }
  .tpo-topbar-icon-mobile {
    display: block !important;
  }
  .tpo-mobile-menu-container-style {
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
}
@media (min-width: 992px) {
  .topbar .navigation-menu>li>a span {
    vertical-align: middle;
    padding: 8px 6px;
    border-radius: 4px;
  }

  .topbar #navigation {
    display: block;
    border-right: 1px solid #e3ebf6;
    left: -175px;
    right: 0;
    padding-left: 50px;
  }

}

@media (max-width: 992px) {
  .topbar #navigation {
    display: block;
    border-right: 1px solid #e3ebf6;
    right: 0;
  }

  .topbar-icon-mobile {
    display: block !important;
  }
  .hide-after-lg {
    display:none!important;
  }
  .topbar .navigation-menu > li > a {
    color: #1f2538;
    padding: 10px 15px;
    font-size: 13px;

  }
}

@media (max-width: 1776px) {
  .topbar .navigation-menu>li>a span {
    vertical-align: middle;
    padding: 8px 1px;
    border-radius: 4px;
  }

  .topbar #navigation {

    border-right: 1px solid #e3ebf6;
  }

  .navigation-menu>li a {
    display: block;
    font-size: 11px;
    transition: all .1s ease;
    line-height: 18px;
    padding-left: 6px;
    padding-right: 6px;
  }

  .navigation-menu>li a i {
    margin-right: 5px;
  }
}
.activities-list-height {
  min-height: 45vh;
  height: 89%;
}
@media (min-width:1980px) {
  .activities-list-height {
    min-height:53vh;
  }
}
@media (min-width:2560px) {
  .activities-list-height {
    min-height:60vh;
  }
}
@media (min-width:3840px) {
  .activities-list-height {
    min-height:75vh;
  }
}

.analytic-dash-activity > .simplebar-wrapper > .simplebar-mask > .simplebar-offset > .simplebar-content-wrapper {
  height: 90vh !important;
  overflow-y: scroll!important; ;
}
path#apexcharts-radialbarTrack-0 {
  stroke-width: 5px !important;
}

.apexcharts-radialbar-slice-0 {
  stroke-width: 5px !important;
}

.apexcharts-canvas {
  transform: scale(1.5) !important;
}

.content-wrapper {
  padding-right: 1px;
  margin-left: 50px;
  margin-top: 57px;
}

.card {
  border-radius: 6px;
}

/***** SEANS CUSTOM ****/

.card {
  border: 1px solid #d3ddeb !important;
}

.card .card-header {
  background-color: #e7efff;
}

.modal-header {
  background-color: #e7efff !important;
}

.modal-header .modal-title {
  color: #303e67 !important;
  font-size: 18px;
}

.form-select,
.form-control {
  border-radius: 0.5rem !important;
}

.p-datatable-header {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;

}

.p-paginator-bottom {
  border-bottom-left-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.modal-content {
  border-radius: 0.5rem !important;
}

.select2-container .select2-selection--single {
  height: 33.5px !important;
  border-radius: 0.5rem
}

.select2-container .select2-selection--single .select2-selection__rendered {
  padding-left: 11px;
  padding-top: 2px;
}

/***** END SEANS CUSTOM ****/

span.select2-container.select2-container--classic.select2-container--open {
  z-index: 9999 !important;
}

.topbar-icon-mobile {
  display: none;
}

div.marked {
  padding: 0 !important;
  border-radius: 3px;
  background-color: yellow !important;
  z-index: 9999;
  display: inline-block;
}

span.marked {
  color: red;
  background-color: yellow;
}


.nav.nav-tabs .nav-item.show.active,
.nav.nav-tabs .nav-item.show:focus,
.nav.nav-tabs .nav-link.active
{
  color: #1761fd;
  background-color: #9ad0ff1c;
  border-color: transparent transparent #1761fd;
  border-bottom-width: 2px;
  font-weight: 700!important;
}
.main-sidenav-menu.left-sidenav-menu li {
  margin-top: 2px;
}
.main-sidenav-menu.left-sidenav-menu li ul li>a:hover,
.main-sidenav-menu.left-sidenav-menu li.mm-active .nav-item.active a.nav-link.active i,
.main-sidenav-menu.left-sidenav-menu li>a:hover,
.main-sidenav-menu.navbar-custom-menu .has-submenu.active .submenu li.active>a {
  color: #2f6bf9;
  font-weight:700;
  background-color: #9ad0ff1c;
}

.main-sidenav-menu.left-sidenav-menu li>a:hover i {
  background-color: #9ad0ff00;
}
.main-sidenav-menu.left-sidenav-menu li.mm-active>a {
  color: #1761fd;
  border-radius: 4px;
  background-color: #9ad0ff1c !important;
  font-weight: 700!important;
}
.main-sidenav-menu.left-sidenav-menu li.mm-sub-active>a {
  color: #1761fd;
  border-radius: 4px;
  background-color: #9ad0ff1c;
  font-weight: 400!important;
}
.ng2-tag-input {
  border: 0 !important;
  padding: 0 !important;
}
.main-sidenav-menu.nav-tabs .nav-link {
  border: 0px solid transparent!important;
  padding: 7px 7px 7px 12px!important;
}
.nav-link {
  color: #7081b9;
}

.nav-tabs .nav-link {
  border: 0px solid transparent!important;

}
.tpo-report-card {
  box-shadow: 2px 1px 9px 0px rgb(0 0 0 / 0%)!important;
  -webkit-box-shadow: 2px 1px 9px 0px rgb(0 0 0 / 0%)!important;
}
.nav-link {
  color: #7081b9;
}
.input-tags-overrider {
  border: 1px solid #e3ebf6 !important;
  padding: 0.375rem 0.75rem !important;
  height: inherit !important;
}

/*Somewhat suspicious rule that affects contacts sms details: may require deeper testing on modals.*/
@media (min-width: 576px) {
  .modal-dialog-scrollable {
    height: unset !important;
  }
}

@media (max-width: 1281px) {
  .navigation-menu>li a i {
    display: none;
  }
}

@media (max-width: 991px) {
  #navigation {
    top:53px;
    width: 220px;
    height:93vh;
    max-height: 93vh;
    -webkit-transition: 0.2s ease-out;
    -moz-transition: 0.2s ease-out;
    transition: 0.2s ease-out;
    box-shadow: -2px 8px 6px 0px rgb(0 0 0 / 56%);
  }

  .navigation-menu>li a i {
    display: inline-block;
  }

  .float-end-mobile {
    float: unset !important;
  }

  .calls-on-button-mobile {
    width: 100%;
    margin-bottom: 10px;
  }
}
@media (max-width:768px) {
  .content-replacer {
    position: relative;
    top: -30px;
    height: 257px;
    margin-bottom: 10px;
}
.navbar-custom .nav-link {
  padding: 0 0.35rem;

}

}
@media (max-width:576px) {
  .nav-mobile {
    display: block!important;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    text-align: start!important;
  }
  .nav-mobile li {
    border-radius:5px;
    margin-bottom:5px;
    box-shadow: 2px 1px 9px 0px rgb(0 0 0 / 15%);
      -webkit-box-shadow: 2px 1px 9px 0px rgb(0 0 0 / 15%);
      -moz-box-shadow: 2px 1px 9px 0px rgba(0, 0, 0, 0.15);
  }
  .sticky-nav-tabs {
    top: unset;
    z-index: 15;
    background-color: white;
    width: 100%;
    position: relative;
  }
  li.p-disabled > a.p-menuitem-link > .p-steps-title {
    display:none!important;
  }
}
.p-treenode-leaf > .p-treenode-content > .p-tree-toggler {
  height: 1px!important;
  background-color: lightgray!important;
  border-bottom: 1px solid lightgray!important;
  width: 2.6rem!important;
  visibility: inherit!important;
  margin-left: -10px!important;
}
.p-tree .p-treenode-children .p-treenode {
  border-left: 1px solid lightgrey;
  margin-left: 7px;
}
.dropdown.ngx-contextmenu > ul > li .dropdown-item.hasSubMenu{
  padding:15px;
  border-bottom: 1px solid var(--bs-light);
}
.dropdown.ngx-contextmenu > ul > li .dropdown-item.hasSubMenu:hover{
  background-color: var(--bs-primary);
  color: white;
}
.card-stickified {
  position:sticky;
  top:95px
}
.card-stickified-open-dialer {
  position:sticky;
  top:138px
}
.urla-menu-mobile-visibility {
  display:none;
}
@media only screen and (max-width : 576px) {
  .hide-on-screen {
      display:none;
  }
}

@media screen and (max-width: 767px) {
  input, select, textarea {
    font-size: 16px !important;
  }
}


/*task dashboard*/
.taskdashhead {
  color: #ffffff;
  background: #151412;
  background: -moz-linear-gradient(left, #6E5E55 93%, #151412 100%);
  background: -webkit-gradient(linear, left top, right top, color-stop(93%, #6E5E55), color-stop(100%, #151412));
  background: -webkit-linear-gradient(top, #6E5E55 93%, #151412 100%);
  background: -o-linear-gradient(top, #6E5E55 93%, #151412 100%);
  background: -ms-linear-gradient(top, #6E5E55 93%, #151412 100%);
  background: linear-gradient(to bottom, #6E5E55 0%, #151412 90%);
}
