:root {
	--required-color: #a94442 !important;
	--required-bcolor: #f2dede !important;
	--invalid-bcolor: #f2dede;
	--invalid-color: #a94442;
	--requested-color: #f7c522;
	--requested-bcolor: #fffdf6;
}

.loan-app {
  label {
    opacity: 100%;
  }

	select:focus {
		background: var(--light-color) !important;
	}

	--table-frame-padding: 0 10px;
	.borrower-container {
		padding-bottom: 5px;
		margin-bottom: 15px;
		border: solid 1px #dfdfdf;
		//background: #f5f5f5;
		background: white;
	}
  .negative-margin-5 {
    margin-bottom: -9px;
  }
	.use-urla-warning {
		font-weight: 600;
    	font-size: 1.5rem;
    	padding-bottom: 7px;
	}

	input[type="date"]::-webkit-calendar-picker-indicator {
		display: none;
		-webkit-appearance: none;
	}

	.dropdown-menu {
		z-index: 3;
	}

	.dropdown-menu-form {
		position: static !important;
	}

	.text-decoration {
		-webkit-text-decoration-line: line-through; /* Safari */
   	text-decoration-line: line-through;
	}

	li.active a.ng-binding {
		color: var(--black-color) !important;
		background-color: var(--aqua-blue-color) !important;
		text-decoration: none;
	}

	.grid-header-link {
		font-weight: 400;
	}

	li a.ng-binding {
		color: var(--dark-gray-color);
		background-color: transparent;
		text-decoration: none;
		cursor: pointer;
		padding-left: 20px;
		padding-right: 20px;
		font-size: 1.1em;
	}

	.nav-tabs {
		border-bottom: none;
	}

	a.link {
		color: var(--blue-color);
		background-color: transparent;
		border: none;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
	}

	.modal-lg .modal-content {
		margin: 0 auto;
		width: 900px;
	}

	.modal-sm .modal-content {
		margin: 0 auto;
		width: 400px;
	}

	.modal-content {
		margin: 0 auto;
		width: 600px;
	}

	.red-icon {
		color: var(--red-color);
	}

	.grid-layout {
		width: 100%;
    .disabled-field {
      background-color: var(--light-bcolor) ;
			border: solid 1px var(--light-gray-color);
			border-radius: 5px;
			margin-top: 7px;
    }
		.invalid-required-field {
			background-color: var(--required-bcolor) ;
			border-bottom-width: 2px;
			box-sizing: border-box;
			border: solid 1px var(--required-color);
			border-radius: 5px;
			margin-top: 7px;
      &[disabled] {
        background-color: var(--light-bcolor);
      }
		}
		.invalid-requested-field {
			background-color: var(--requested-bcolor) ;
			border-bottom-width: 2px;
			box-sizing: border-box;
			border: solid 1px var(--requested-color);
			border-radius: 5px;
			margin-top: 7px;
      &[disabled] {
        background-color: var(--light-bcolor);
      }
		}
		.grid-layout-row {
			margin: 0;
			position: relative;
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			flex-wrap: wrap;
			box-sizing: border-box;
			width: 100%;
			margin-top: -1px;
			border-top: solid 1px transparent;
			border-left: solid 1px transparent;
			>* {
				background-color: var(--white-color);
				border-right: solid 1px transparent;
				border-bottom: solid 1px transparent;
			}
			[class*="col-"] {
				display: flex;
				flex-direction: column;
				padding: 0;
			}
			.radio-disabled {
				pointer-events: none;
				opacity: 0.5;
			}
			.line-height{
				margin: 0;
				line-height: 15px;
			}
		}
		.grid-row-separator {
			border: none;
			background-color: transparent;
			line-height: 12px;
		}
		.grid-layout-text {
			line-height: 25px;
		}
		.grid-layout-spacer {
			line-height: 25px;
		}
		.table-header {
			.grid-layout-text {
				background-color: var(--white-color);
				border-right: none;
				text-transform: uppercase;
				font-weight: 700;
				color: var(--blue-color);
				text-align: left;
				//&:last-child {
				//	border-right: solid 1px var(--light-gray-color);
				//}
			}
		}
		.grid-header {
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			flex-wrap: wrap;
			width: 100%;
			i {
				margin-right: 5px;
			}
		}
		.grid-header-left {
			display: block;
			padding: 8px 12px;
			background-color: var(--light-bcolor);
			margin-bottom: 1px;
			width: 50%;
			min-height: 1px;
			border: none;
			font-weight: 600;
			.proposed-monthly-payment,
			.loan-features,
			.arm-features {
				span {
				//font-size: 1.24rem;
        font-weight: 450;
			}
			}
			span {
				display: inline;

			}
		}
		.grid-header-right {
			display: block;
			padding-bottom: 8px;
			padding-top: 8px;
			background-color: var(--light-bcolor);
			margin-bottom: 1px;
			width: 50%;
			min-height: 1px;
			border: none;
			text-align: right;
			span {
				display: inline;
				//font-size: 1.54rem;
			}
		}
		.form-input {
			padding: 8px 12px;
			height: 100%;
      @extend .text-right;
			.input-label {
				display: flex;
				align-items: center;
				line-height: 25px;
				height: 25px;
				position: relative;
				text-overflow: ellipsis;
				text-size-adjust: 100%;
				white-space: nowrap;
				overflow: hidden;
				.input-label-overflow {
					text-overflow: ellipsis;
					text-size-adjust: 100%;
					overflow: hidden;
					white-space: nowrap;
				}
			}
			.input-element {
				display: block;
				//font-size: 1.24rem;
				//font-weight: 550;
				width: 100%;
				padding-left: 5px;
				height: 25px;
				margin: 0;
				padding: 0;
				line-height: 25px;
				background-color: transparent;
				border: 0;
				border-bottom: solid 1px var(--light-gray-color);
				&::placeholder {
					font-style: italic;
					color: var(--light-gray-color);
					font-weight: 400;
				}
				&[masked='true']::placeholder {
					color: darkblue !important;
				}
			}
			.input-readonly {
				display: block;
				font-size: 1.24rem;
				font-weight: 550;
				width: 100%;
				padding-left: 5px;
				height: 25px;
				margin: 0;
				line-height: 25px;
				background-color: transparent;
				border: 0;
				// border-bottom: solid 1px var(--light-gray-color);
			}
			.input-readonly-ellipsis {
				@extend .input-readonly;
				white-space: nowrap;
            	text-overflow: ellipsis;
				overflow: hidden;
				width: 71px;
			}
			option {
				font-style: normal;
				color: var(--option-color);
				font-weight: normal;
				background-color: var(--white-color);
			}
		}
		.dropdown-invalid{
			.form-input>span{
				box-sizing: border-box;
				border-bottom: solid 2px var(--required-color);
			}
		}
		.multiselect-dropdown-invalid{
			.form-input{
				box-sizing: border-box;
				border-bottom: solid 2px var(--required-color);
			}
		}
		.grid-cell-tag {
			.cell-content {
				.form-input {
					padding: initial;
					height: unset;
					width: unset;
					background-color: transparent;
					outline: unset;
					z-index: unset;
				}
			}
			.form-input {
				.cell-content {
					display: flex;
					line-height: 25px;
				}
			}
		}
		.grid-cell-tag.space-between {
			.form-input {
				.cell-content {
					justify-content: space-between;
				}
			}
		}
		.grid-cell-tag.vertical {
			.form-input {
				.cell-content {
					display: block;
				}
			}
		}
		.grid-cell-tag.text-right {
			.form-input {
				b {
					display: inline-block;
					width: 100%;
				}
			}
		}
		.grid-cell-tag.text-center {
			.form-input {
				b {
					display: inline-block;
					width: 100%;
				}
			}
		}
		.grid-cell.ng-invalid-required {
			.form-input {
				background-color: initial;
				outline: initial;
			}
		}
		.ng-invalid-required {
			&.form-input {
				.input-label {
					font-weight: 600;
					.input-label-overflow {
						text-overflow: ellipsis;
						text-size-adjust: 100%;
						overflow: hidden;
						white-space: nowrap;
					}
					&:after {
						content: "*";
						//font-weight: 600;
						//margin-top: 3px;
						margin-left: 3px;
						//font-size: 1.76rem;
						//line-height: 1;
						align-self: baseline;
					}
				}
				.input-element {
					height: 25px;
					background-color: var(--required-bcolor);
					border-bottom-color: var(--required-color);
					border-bottom-width: 2px;
					box-sizing: border-box;
				}
				select.input-element {
					font-style: italic;
					font-weight: 400;
				}
			}
			.boolean-checkbox-readonly {
				background-color: transparent;
				border-bottom: none;
			}
		}
		.ng-invalid-requested {
			&.form-input {
				.input-label {
					font-weight: 600;
					.input-label-overflow {
						text-overflow: ellipsis;
						text-size-adjust: 100%;
						overflow: hidden;
						white-space: nowrap;
					}
					&:after {
						content: "*";
						//font-weight: 600;
						//margin-top: 3px;
						margin-left: 3px;
						//font-size: 1.76rem;
						//line-height: 1;
						align-self: baseline;
					}
				}
				.input-element {
					height: 25px;
					background-color: var(--requested-bcolor);
					border-bottom-color: var(--requested-color);
					border-bottom-width: 2px;
					box-sizing: border-box;
				}
				select.input-element {
					font-style: italic;
					color: var(--light-gray-color);
					font-weight: 400;
				}
			}
			.boolean-checkbox-readonly {
				background-color: transparent;
				border-bottom: none;
			}
		}
		.ng-invalid-pattern,
		.ng-invalid-date,
		.ng-invalid-mask {
			.form-input {
				.input-label {
					font-weight: 600;
					&:after {
						content: "*";
						//font-weight: 600;
						//margin-top: 3px;
						margin-left: 3px;
						//font-size: 1.76rem;
						//line-height: 1;
						align-self: baseline;
					}
				}
				.input-element {
					height: 25px;
					background-color: var(--invalid-bcolor);
					border-bottom-color: var(--invalid-color);
					border-bottom-width: 2px;
					box-sizing: border-box;
				}
			}
		}
		radio-group {
			.form-input {
				.input-readonly {
					border: 0;
				}
			}
		}
		select-many-checkbox {
			.form-input {
				.input-readonly {
					border: 0;
				}
			}
		}
		.ng-empty {
			.form-input {
				select.input-element {
					font-style: italic;
					color: var(--light-gray-color);
					font-weight: 400;
				}
			}
		}
		.form-input-required {
			option {
				font-style: normal;
				color: var(--option-color);
				font-weight: normal;
				background-color: var(--white-color);
			}
		}
	}
	.grid-layout.border-outline {
		margin-top: -1px;
		border: solid 1px var(--light-gray-color);
	}
	.grid-layout.no-top-border {
		border-bottom: solid 1px transparent;
	}
	.grid-layout.has-border {
    .form-input {
			border: solid 1px var(--light-gray-color);
			border-radius: 5px;
			background-color: var(--white-color);
			.input-label {
				text-transform: uppercase;
			}
			.input-element {
				border: 0;
			}
			.input-readonly {
				border: 0;
			}
		}
		.grid-layout-row {
			margin: 0px;
			padding: 0px;
			//margin-top: -1px;
			//border-top: solid 1px var(--light-gray-color);
			//border-left: solid 1px var(--light-gray-color);
			>* {
				padding: 0px 2px;
				margin-bottom: 4px;
				//margin-left: 0px;
				//margin-top: 0px;
				//border-right: solid 1px var(--light-gray-color);
				//border-bottom: solid 1px var(--light-gray-color);
				box-sizing: border-box;
				background-color: var(--light-bcolor);
			}
			>*:first-child{
				padding-left: 0px;
				border-top-left-radius: 5px;
				border-bottom-left-radius: 5px;
			}
			>*:last-child{
				padding-right: 0px;
				border-top-right-radius: 5px;
				border-bottom-right-radius: 5px;
			}
			.no-border-right {
				border-right: solid 1px transparent;
			}
			.left-space {
				padding-left: var(--space-xs);
			}
			.ls-2 {
				padding-left: var(--space-sm);
			}
			.right-space {
				padding-right: var(--space-xs);
				background-color: var(--white-color);
			}
			.both-space {
				padding: var(--space-xs);
			}
			.bg-radius {
				border-radius: 5px;
			}
			.br {
				border-radius: 5px;
			}
			.bg-color {
				background-color: var(--white-color);
			}
		}
		.grid-layout-spacer {
			//margin-left: 0px;
			//margin-top: 0px;
			//border-right: solid 1px var(--light-gray-color);
			//border-bottom: solid 1px var(--light-gray-color);
			box-sizing: border-box;
			//background-color: var(--light-bcolor);
			//border: solid 2px var(--white-color);
			//border-radius: 5px;
			//background-color: transparent;
		}
		.grid-layout-text {
			//margin-left: 0px;
			//margin-top: 0px;
			//border-right: solid 1px var(--light-gray-color);
			//border-bottom: solid 1px var(--light-gray-color);
			box-sizing: border-box;
			//background-color: var(--light-bcolor);
			//background-color: transparent;
		}
		.no-bottom-border.grid-layout-row {
			>* {
				border-bottom: none;
			}
		}
		.ng-invalid-required {
      background-color: var(--required-bcolor);
      //outline: solid 1px var(--required-color);
      border: solid 1px  var(--required-color);
      z-index: 1;
			.form-input {
				background-color: var(--required-bcolor);
				//outline: solid 1px var(--required-color);
				border: solid 1px  var(--required-color);
				z-index: 1;
			}
			.select-many-checkbox {
				background-color: transparent;
				border-bottom: none;
			}
			.radio-group {
				background-color: transparent;
				border-bottom: none;
			}
			.boolean-checkbox {
				background-color: transparent;
				border-bottom: none;
			}
		}
		.ng-invalid-requested {
			background-color: var(--requested-bcolor);
			//outline: solid 1px var(--required-color);
			border: solid 1px  var(--requested-color);
			z-index: 1;
				  .form-input {
					  background-color: var(--requested-bcolor);
					  //outline: solid 1px var(--required-color);
					  border: solid 1px  var(--requested-color);
					  z-index: 1;
				  }
				  .select-many-checkbox {
					  background-color: transparent;
					  border-bottom: none;
				  }
				  .radio-group {
					  background-color: transparent;
					  border-bottom: none;
				  }
				  .boolean-checkbox {
					  background-color: transparent;
					  border-bottom: none;
				  }
			  }
		.ng-invalid-date,
		.ng-invalid-pattern,
		.ng-invalid-mask {
      background-color: var(--invalid-bcolor);
				//outline: solid 1px var(--invalid-color);
				border-color: var(--invalid-color);
				z-index: 2;
			.form-input {
				background-color: var(--invalid-bcolor);
				//outline: solid 1px var(--invalid-color);
				border-color: var(--invalid-color);
				z-index: 2;
			}
		}
		.grid-cell-tag {
			.my-transclude {
				.form-input {
					padding: initial;
					height: unset;
					width: unset;
					background-color: transparent;
					outline: none;
					z-index: unset;
					border: none;
				}
			}
		}
		.grid-cell.ng-invalid-required {
			background-color: var(--required-bcolor);
			//outline: solid 1px var(--required-color);
			border-color: var(--required-color);
			z-index: 1;
		}
	}
	.table-responsive {
		display: block;
		position: relative;
		top: 0px;
		left: 0px;
		right: 0px;
		overflow-x: auto;
		// background-color: var(--blue-color);
		border-bottom: 1px solid var(--data-table-color);
	}
	.grid-layout.data-table {
		// border-left: solid 1px var(--data-table-color);
		// border-right: solid 1px var(--data-table-color);
		display: table;
		width: 100%;
		// background-color: var(--data-table-color);
		// border-collapse: collapse;
		.table-bottom-border {
			border-bottom: var(--border) solid var(--data-table-color);
		}

		.grid-layout-row {
			display: table-row;
			// border-bottom: solid 1px var(--data-table-color);
			// background-color: var(--white-color);
			width: 100%;

			.no-border {
				border: none !important;
        width: 100%;
			}
			.output-text {
				display: table-cell;
				padding: 8px 12px;
			}
			command-link, currency-input, integer-input, boolean-checkbox, text-input, dropdown {
				display: table-cell;
			}
			ng-switch {
				display: table-cell;
				height: 100%;
				.output-text, command-link, currency-input, integer-input, boolean-checkbox, text-input, dropdown {
					border-left: none;
				}
			}
			.form-input {
				height: 100%;
				command-link {
					i.fa {
						margin-right: 0px;
					}
				}
			}
		}
		.grid-layout-row.data-table-header {
			background-color: transparent;
			command-link {
				font-weight: 600;
				font-size: 1.1rem;
				text-decoration: none;
				color: var(--pale-sky-color);
				a {
					color: var(--black-color);
				}
			}
			.output-text {
				font-weight: 600;
				font-size: 1.1rem;
				text-decoration: none;
				color: var(--pale-sky-color);
			}
		}
	}
	.width-100-px {
		width: 100px;
		min-width: 100px;
	}
	.balance {
		width: 105px;
	}
	.width-60-px {
		width: 60px;
		min-width: 60px;
    }

	.table-col-5-percent {
		width: 5%;
	}
	.table-col-10-percent {
		width: 10%;
	}

	.table-col-15-percent {
		width: 15%;
	}

	.table-col-20-percent {
		width: 20%;
	}

	.table-col-30-percent {
		width: 30%;
	}

	.table-col-40-percent {
		width: 40%;
	}

	.table-col-50-percent {
		width: 50%;
	}

	.table-col-60-percent {
		width: 60%;
	}

	// düzeltilen başlangıç
    %col{
        float: left;
		position: relative;
		min-height: 1px;
		padding-right: 15px;
		padding-left: 15px;
    }
    @mixin col($width: 40px) {
        width:$size;
        height:$size;
    }
	.col-10-percent {
        @extend %col;
		width: 10%;
	}
	.col-20-percent {
		@extend %col;
		width: 20%;
	}
	.col-30-percent {
		@extend %col;
		width: 30%;
	}
	.col-40-percent {
		@extend %col;
		width: 40%;
	}
	.col-60-percent {
		@extend %col;
		width: 60%;
	}
	.col-100-percent {
		@extend %col;
		width: 100%;
    }
    // düzeltilen bitiş
	.col-span-all {
		column-span: all;
	}
    //düzeltilmiş
	.calculated-field {
		display: flex;
		align-items: center;
		position: relative;
		//width: 100%;
		padding-left: 0px;
    padding-right: 4px;
		background-color: transparent;
		a {
			padding-right: 0px;
			padding-left: 5px;
        }
        .align-right {
            span {
                text-align: right;
            }
            input {
                text-align: right;
            }
            a {
                padding-right: 5px;
                padding-left: 0px;
            }
        }
	}
	select-many-checkbox {
		.select-many-checkbox {
			min-height: 25px;
			line-height: 25px;
			box-sizing: border-box;
        }
        .ng-invalid-required {
            .select-many-checkbox {
                background-color: var(--required-bcolor);
                border-bottom: solid 2px var(--required-color);
            }
        }
	}
	radio-group {
		.radio-group {
			min-height: 25px;
			line-height: 25px;
			box-sizing: border-box;
        }
        .ng-invalid-required {
            .radio-group {
                background-color: var(--required-bcolor);
                border-bottom: solid 2px var(--required-color);
            }
        }
	}
	boolean-checkbox {
		.boolean-checkbox {
			min-height: 25px;
			line-height: 25px;
			box-sizing: border-box;
        }
        .ng-invalid-required {
            .boolean-checkbox {
                background-color: var(--required-bcolor);
                border-bottom: solid 2px var(--required-color);
            }
        }
	}
	.select-many-checkbox {
		.select-many-checkbox-item {
			cursor: default;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			white-space: nowrap;
			font-weight: normal;
			position: relative;
			display: flex;
			float: left;
			margin-right: 10px;
			min-height: 25px;
			line-height: 25px;
			margin-bottom: 0;
			padding-left: var(--space-xs);
			input {
				display: inline;
				margin: 0;
				margin-right: 5px;
				height: 25px;
				line-height: 25px;
			}
        }
        &-vertical {
            .select-many-checkbox-item {
                float: none;
				margin-bottom: 10px;
            }
        }
        &-horizontal {
            .select-many-checkbox-item {
                position: relative;
                display: flex;
                float: left;
                margin-right: 10px;
                min-height: 25px;
                line-height: 25px;
                margin-bottom: 0;
                input {
                    height: 25px;
                    line-height: 25px;
                }
            }
        }
        &-checkbox-space-between {
            justify-content: space-between;
            display: flex;
            float: none;
        }
		&-invalid-bcolor {
			background-color: var(--required-bcolor) !important;
		}
	}
	.radio-group {
		.radio-group-item {
			cursor: default;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			white-space: nowrap;
			font-weight: normal;
			position: relative;
			display: flex;
			float: left;
			margin-right: 10px;
			min-height: 25px;
			line-height: 25px;
			margin-bottom: 0;
			input {
				display: inline;
				margin: 0;
				margin-right: 5px;
				height: 25px;
				line-height: 25px;
			}
        }
        &-horizontal {
            .radio-group-item {
                position: relative;
                display: flex;
                float: left;
                margin-right: 10px;
                min-height: 25px;
                line-height: 25px;
                margin-bottom: 0;
                input {
                    height: 25px;
                    line-height: 25px;
                }
            }
        }
        &-vertical {
            .radio-group-item {
                float: none;
            }
        }
        &-space-between {
            justify-content: space-between;
            display: flex;
            float: none;
        }
	}
	.boolean-checkbox {
		.boolean-checkbox-item {
			cursor: default;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
			white-space: nowrap;
			font-weight: normal;
			position: relative;
			display: flex;
			float: left;
			margin-right: 10px;
			min-height: 25px;
			line-height: 25px;
			margin-bottom: 0;
			input {
				display: inline;
				margin: 0;
				margin-right: 5px;
				height: 25px;
				line-height: 25px;
			}
		}
		display: block;
		white-space: nowrap;
		input {
			display: inline;
		}
		span {
			display: inline;
			white-space: normal;
			cursor: default;
			-webkit-touch-callout: none;
			-webkit-user-select: none;
			-khtml-user-select: none;
			-moz-user-select: none;
			-ms-user-select: none;
			user-select: none;
		}
	}
	.command-link {
		line-height: 25px;
		i.fa {
			padding-left: 0px;
			margin-right: 5px;
		}
	}
	.inline-confirm {
		margin-top: 10px;
		padding: 8px 12px;
		color: var(--confirm-color);
		background-color: var(--confirm-bcolor);
		border: solid 1px var(--required-color);
		table {
			width: 100%;
			border-collapse: collapse;
		}
		.inline-confirm-btn-grp {
			width: 150px;
			text-align: right;
		}
		.inline-confirm-btn {
			color: var(--white-color);
			background-color: var(--red-color);
			border-color: var(--red-color);
			box-shadow: 0 0 4px rgba(59,58,58,.07);
			border: 1px solid transparent;
			padding: .3rem 1.5rem;
			font-size: 1.4rem;
			line-height: 1.5;
			border-radius: 4px;
			margin-right: 5px;
		}
		.inline-confirm-link {
			color: var(--light-gray-color);
			background: none;
			border: none;
			box-shadow: none;
			padding: 0;
			font-size: 1.4rem;
			line-height: 1.5;
			margin-right: 5px;
		}
	}
	.borrower-order {
		color: var(--light-blue-color);
		margin-left: 15px;
		&:hover {
			text-decoration: underline;
			cursor: pointer;
		}
	}
	.text-danger {
		color: var(--red-color);
	}
	.text-success {
		color: var(--khaki-color) !important;
	}
	.borrower-container {
		.side-border-with-padding {
			margin-top: -1px;
			border-left: solid 1px var(--light-gray-color);
			border-right: solid 1px var(--light-gray-color);
			padding-left: 11px;
			padding-right: 11px;
		}
		.border-bottom {
			border-bottom: solid 1px var(--light-gray-color);
		}
		.grid-header-right {
			padding-top: 8px;
        	padding-bottom: 8px;
			span {
				margin-left: 15px;
			}
		}
	}
	.grid-layout-row {
		.required-bold {
			font-weight: 600;
			span {
				&:after {
					content: "*";
					//font-weight: 600;
					//margin-top: 3px;
					margin-left: 3px;
					//font-size: 1.76rem;
					//line-height: 1;
					align-self: baseline;
				}
			}
		}
	}
	.form-submitted {
		.grid-layout {
			.ng-invalid-required {
				.form-input {
					.input-element {
						background-color: var(--invalid-bcolor);
						border-bottom-color: var(--invalid-color);
					}
				}
			}
		}
    }
	sup {
		top: -.5em;
		margin-left: 3px;
	}
	.top-right-close {
		position: absolute;
		display: block;
		z-index: 2;
		width: 1.1rem;
		height: 1.1rem;
		font-size: 1.1rem;
		line-height: 1.1rem;
		top: -0.6rem;
		right: -0.6rem;
		border-radius: 0.6rem;
		background-color: var(--white-color);
		&:hover {
			color: var(--red-color);
			cursor: pointer;
		}
	}
	.top-right-copy {
		position: absolute;
		display: block;
		z-index: 2;
		width: 1.8rem;
		height: 1.8rem;
		font-size: 1.8rem;
		top: 0.5rem;
		right: 0.5rem;
		background-color: var(--white-color);
		&:hover {
			color: var(--red-color);
			cursor: pointer;
		}
	}
	.top-right-refresh {
		position: absolute;
		display: block;
		z-index: 2;
		top: -3.2rem;
		right: 4.5rem;
		&:hover {
			cursor: pointer;
		}
	}
	.top-right-checkbox {
		position: absolute;
		display: block;
		z-index: 2;
		font-size: 1.2rem;
		top: 0.5rem;
		right: 1rem;
		background-color: none;
	}
	.borrower-manage-top-right-close {
		position: relative;
		display: block;
		z-index: 2;
		font-size: 1.8rem;
		line-height: 1.8rem;
		top: -1.9rem;
		right: -2.9rem;
		border-radius: 0.9rem;
	}
	.top-left-close {
		position: absolute;
		display: block;
		z-index: 2;
		font-size: 1.4rem;
		line-height: 1.4rem;
		top: -1rem;
		border-radius: 0.9rem;
		background-color: var(--light-drag-color) !important;
		border: 1px solid var(--light-color);
		color: var(--body-color);
	}

	.group-container-repeat {
		background-color: var(--light-bcolor);
		border-radius: 0.9rem;
		border: 1px solid var(--gray-color);
		//border: solid 1px #d3d3d3;
		margin: 8px 8px 5px 8px;
		box-shadow: 0 0 1px 0 rgba(0, 0, 0, .35);
		padding: 3px;
		overflow: visible;
		position: relative;
		.pending-delete {
			background-color: var(--red-color);
		}
	}

	.group-container-repeat-pending-delete {
		@extend .group-container-repeat;
		border-color: var(--red-color);
	}

	.field-description {
		//font-size: 1.4rem;
		.left-space {
			padding-left: var(--space-xs);
		}
		.right-space {
			padding-right: var(--space-xs);
			background-color: var(--white-color);
		}
		span {
			font-weight: bold;
		}
	}
	.form-field-label {
		font-size: 1.3rem;
		padding-top: 8px;
	}
	.field-editor-container {
		padding: 10px 10px 6px 10px;
	}
	.form-field-inline {
		input[type=checkbox] {
			margin-right: 5px;
			width: 15px;
			height: 15px;
			cursor: inherit;
			flex-shrink: 0;
			margin-top: 4px;
			padding-bottom: 8px;
		}
		input[type=radio] {
			margin-right: 5px;
			width: 15px;
			height: 15px;
			cursor: inherit;
			flex-shrink: 0;
			margin-top: 4px;
			padding-bottom: 8px;
		}
	}
	span.like-input-readonly {
		padding-left: 8px;
		font-weight: 550;
	}
	.drag-menu {
		position: relative;
		min-height: 300px;
		background-color: var(--light-drag-color);
		display: flex;
		flex-direction: column;
		.manage-borrowers-dialog-content {
			display: flex;
			flex-direction: column;
			height: 100%;
			.close {
				position: absolute;
				right: 0;
				margin-top: 10px;
				margin-right: 10px;
				color: var(--pale-sky-color);
			}
			>.header {
				padding: 25px 40px;
				>h4 {
					font-size: 1.8rem;
					font-weight: 400;
					color: var(--body-color);
				}
			}
			.body {
				padding: var(--table-frame-padding);
				.borrowers-collection {
					display: flex;
					flex-direction: column;
					height: 100%;
					min-height: 150px;
					padding: 5px 25px;
					background-color: var(--white-color);
					border-bottom-left-radius: 3px;
					border-bottom-right-radius: 3px;
				}
				.new-borrowers-collection {
					display: flex;
					flex-direction: column;
					height: 100%;
					min-height: 55px;
					padding: 5px 25px;
					background-color: var(--white-color);
					border-bottom-left-radius: 3px;
					border-bottom-right-radius: 3px;
					border-top-left-radius: 5px;
					border-top-right-radius: 5px;
					border-top: 1px solid var(--light-color);
					border-bottom: 1px solid var(--light-color);
					border-left: 1px solid var(--light-color);
					border-right: 1px solid var(--light-color);
				}
				.borrower {
					border-radius: 3px;
					display: flex;
					justify-content: space-between;
					align-items: center;
					padding: 10px;
					background-color: var(--white-color);
					color: var(--body-color);
					box-shadow: 0 0 3px 0 rgba(0, 0, 0, .15);
					margin: 5px;
					cursor: -webkit-grab !important;
					cursor: grab !important;
					&:active {
						cursor: -webkit-grabbing !important;
						cursor: grabbing !important;
					}
					.drag-handle {
						color: var(--light-color);
						font-size: 1.6rem;
					}
					span {
						font-size: 1.2rem;
						font-weight: 400;
					}
				}
				.borrower.active {
					cursor: -webkit-grabbing !important;
					cursor: grabbing !important;
				}
				.borrower.placeholder {
					cursor: default;
					border: 1px dashed var(--light-color);
					box-shadow: none;
					text-align: center;
					color: var(--borrower-placeholder-color);
					background-color: var(--light-drag-color);
				}
				.borrower.assigned {
					background-color: var(--borrower-assigned-color);
					color: var(--black-color);
					width: 100%;
					border: solid 1px lightgray;
				}
				.unassigned-borrowers-section {
					.header {
						padding: 5px;
						>h4 {
							text-transform: uppercase;
							font-size: 1rem;
							font-weight: 400;
							color: var(--pale-sky-color);
						}
					}
				}
				.separator-container {
					padding: 25px 0;
					.separator {
						height: 100%;
						border-right: 1px solid rgba(222, 224, 232, .42);
						margin-right: 50%;
					}
				}
				.assigned-borrowers-section {
					overflow-y: 100%;
					.header {
						>h4 {
							text-transform: uppercase;
							font-size: 1rem;
							font-weight: 400;
							color: var(--pale-sky-color);
							padding: 5px;
						}
					}
					.applications {
						display: flex;
						flex-direction: column;
						background-color: var(--white-color);
						width: auto;
						.container-header{
							border-top-left-radius: 3px;
								border-top-right-radius: 3px;
								padding: 8px 10px;
								background-color: var(--light-gray-color);
								>h4 {
									font-size: 1.3rem;
									font-weight: 500;
									color: var(--white-color);
								}
							background-color: var(--blue-color);
						}
						.application {
							// box-shadow: 0 0 3px 0 rgba(0, 0, 0, .15);
							margin-bottom: 2px;
							margin-top: 10px;
							margin-left: 35px;
							width: 80%;
							.application-header {
								border-top-left-radius: 3px;
								border-top-right-radius: 3px;
								padding: 20px 25px;
								background-color: var(--light-gray-color);
								>h4 {
									font-size: 1.3rem;
									font-weight: 500;
									text-align: center;
									color: var(--white-color);
								}
							}
							.application-header.primary {
								background-color: var(--blue-color);
							}
							.new-application-header {
								border-top-left-radius: 0.9em;
								border-top-right-radius: 0.9em;
								padding: 5px 25px;
								background-color: var(--light-drag-color);
								border-top: 1px dashed var(--light-color);
								border-left: 1px dashed var(--light-color);
								border-right: 1px dashed var(--light-color);
								>h4 {
									font-size: 1.2rem;
									font-weight: 550;
									text-align: center;
									color: var(--borrower-placeholder-color);
								}
							}
						}
					}
				}
				.urla-assigned-borrowers-section {
					.applications {
						margin-bottom: 16px;
						border-left: solid 1px var(--light-color);
						border-top: solid 1px var(--light-color);
						border-bottom: solid 1px var(--light-color);
						border-right: solid 1px var(--light-color);
						border-radius: 0.9em;
						padding-top: 10px;
						background-color: var(--white-color);

						.application {
							margin-bottom: 16px;
							margin-top: 10px;
							margin-left: 12px;
							width: 90%;

							.application-body {
								padding: 5px 25px;
								background-color: var(--white-color);
								border-bottom-left-radius: 3px;
								border-bottom-right-radius: 3px;
								box-shadow: 0 0 2px 0 rgba(0, 0, 0, .15);
							}
							.new-application-body {
								background-color: var(--light-drag-color);
								border-radius: 0.9em;
								margin-left: 2em;

							}
						}
					}
				}

				.assigned-borrowers-section {
					.applications {
						margin-bottom: 16px;
						border-left: solid 1px var(--light-color);
						border-bottom: solid 1px var(--light-color);
						border-right: solid 1px var(--light-color);
						border-bottom-left-radius: 0.6em;
						border-bottom-right-radius: 0.6em;
						.application {
							margin-bottom: 16px;
							.application-body {
								padding: 5px 25px;
								background-color: var(--white-color);
								border-bottom-left-radius: 3px;
								border-bottom-right-radius: 3px;
								box-shadow: 0 0 2px 0 rgba(0, 0, 0, .15);
							}
							.new-application-body {
								border-bottom-left-radius: 0.9em;
								border-bottom-right-radius: 0.9em;
							}
						}
					}
				}
			}
			.footer {
				display: flex;
				justify-content: flex-end;
				padding: 25px;
				border-top: 1px solid var(--light-color);
				>.btn-cancel {
					margin-right: 15px;
				}
				// >.btn-primary {
				// 	padding: 7px 35px;
				// }
			}
		}
		.unassigned-box {
			min-height: 100px;
			height: calc(100% - 39px);
		}
		div.drag-hover {
			border: 1px dashed var(--light-gray-color);
		}
		.dndDragging {
			opacity: 0.7;
		}
		.dndDraggingSource {
			opacity: 0.5;
		}
		.dndPlaceholder {
			background-color: var(--gray-color) !important;
			display: block;
			min-height: 42px;
			width: 100%;
			border: 1px dashed var(--light-gray-color);
		}
	}
	.sub-section {
		font-size: 1.54rem;
		font-weight: 600;
		color: var(--blue-color);
    }
    %btn{
        display: inline-block;
		font-weight: 400;
		text-align: center;
		vertical-align: middle;
		-webkit-user-select: none;
		-moz-user-select: none;
		user-select: none;
		background-color: transparent;
		border: 1px solid transparent;
		padding: .3rem 1.5rem;
		font-size: 1.4rem;
		line-height: 1.5;
		border-radius: 4px;
		transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
    }
	.additional-credit-popover {
		text-align: center;
		padding: 20px 12px;
	}
	.additional-credit-popover-body {
		width: 590px;
	}
	.button-strip {
		padding-top: 16px;
		padding-bottom: 16px;
	}
	.popover {
		max-width: 800px;
	}
	.float-right {
		float: right;
	}
	.panel-heading {
		cursor: pointer;
		a {
			color: var(--black-color);
			font-weight: bold;
		}
	}
	.mt-10 {
		margin-top: 10px !important;
		width: 98%;
		margin: 0 auto;
	}

  .mb-10 {
    margin-bottom: 10px !important;
  }

	.save-button {
		padding-right: 0;
		padding-bottom: 15px;
		text-align: right;
	}

	.unmapped-field {
		border: solid 3px var(--unmapped-field-color) !important
	}

	.btn-bottom {
		float: right;
		// margin-top: -10px;
		margin-bottom: 5px;
	  }

	.sub-header-left {
		h4 {
		text-transform: uppercase;
		font-size: 1.2rem;
		font-weight: 400;
		color: var(--pale-sky-color);
		padding-top: var(--space-xs);
	}
}

	mortgage-info,
	property-info-and-loan-purpose,
	personal-info, employment-income,
	housing-expenses, assets-liabilities,
	transaction-details,
	declarations,
	hmda,

	borrowers-info,
	financial-info,
	real-estate-owned,
	loan-property,
	new-declarations,
	military-service,
	demographic-info,
	loan-originator-info,
	property-loan-info,
	title-information,
	mortgage-loan-info,
	qualifying-the-borr {
		display: block;
	}
}
.btn-left {
	margin-top: 15px;
	text-align: right;
	float: right;
}
.btn-addGroup {
	text-align: right;
	float: right;
	font-weight: 300 !important;
}

.btn-add {
	border-right: 1px solid #ED6C22 !important;
	// color: #F26D21 !important;
	margin-bottom: 7px;
	margin-top: 8px;
}
.btn-add:hover {
		text-decoration: none;
		i,
		span {
			color: white;
		}
}

.btn-add:active,
.btn-add:visited {
	color: #F26D21 !important;
    background: rgba(0, 0, 0, 0.0);
    border: 1px solid #ED6C22;
    box-shadow: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
}

.loan-app.form-submitted {
	.grid-layout {
		.ng-invalid-required {
			.form-input {
				.input-element {
					background-color: var(--invalid-bcolor);
					border-bottom-color: var(--invalid-color);
				}
			}
		}
	}
}

.mt--30{
	margin-top: -30px;
}

.menu-disabled{
	cursor: not-allowed;
	pointer-events: none;
}

.button-on-nav {
	border:none; background-color: transparent; padding: 0;
}

.button-on-nav>i {
	color: #0f79d0;
}

.justify-space-between{
	.form-input>my-transclude{
		justify-content: space-between;
	}
}

.z-auto{
	z-index: auto !important;
}

.no-border {
	border: none !important;
  width: 100%;
}

.no-border.ng-invalid-required{
    background-color: var(--required-bcolor) !important;
    border-bottom: solid 2px var(--required-color) !important;
}
.no-border.ng-invalid-required.input-element{
	border: none !important;
}

.no-border.ng-invalid-requested{
    background-color: var(--requested-bcolor) !important;
    border-bottom: solid 2px var(--requested-color) !important;
}
.no-border.ng-invalid-requested.input-element{
	border: none !important;
}

.disabled {
	pointer-events: none;
  	cursor: default;
}

.bg-transparent{
	background-color: transparent !important;
	input{
		background-color: transparent !important;
	}
}

