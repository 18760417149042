/* You can add global styles to this file, and also import other style files */
@import "~@ng-select/ng-select/themes/default.theme.css";
@import "src/app/modules/loda-nextgen/modules/dashboard/nextgen-dashboard.scss";
@import "~@angular/cdk/overlay-prebuilt.css";
@import '~ngx-print-element/styles.css';
@import '~@ctrl/ngx-emoji-mart/picker';

:root {
  --tpo-main-border-radius: 10px;
  --tpo-font-weight: 600;
  --tpo-frame-padding: 10px 8px 1px 12px;
  --tpo-table-frame-padding: 0 10px;
  --tpo-main-box-shadow: 0px 2px 8px #0000001f;
  --black-color: #000000;
  --blue-color: #0f79d0;
  --light-blue-color: #2196f3;
  --aqua-blue-color: #d9edf7;
  --white-color: #ffffff;
  --orange-color: #ffba39;
  --green-color: #a4cd27;
  --red-color: #c60751;
  --purple-color: #8c5ac2;
  --khaki-color: #647917;
  --pale-sky-color: #667080;
  --header-border-color: #dddddd;
  --header-bg-color: #f5f5f5;
  --gray-color: #ddd;
  --light-gray-color: #e3e3e3; // #a1a7af;
  --light-color: #dee0e8;
  --light-bcolor: #f6f6f6; // #eff0f4;
  --light-drag-color: #f9fafd;
  --dark-gray-color: #777777;
  --data-table-color: #e4e9ef;
  --body-color: #2c3e50;
  --body-bcolor: #f2f4f7;
  --option-color: #3b3a3a;
  --required-color: #f7c522;
  --required-bcolor: #fffdf6;
  --invalid-bcolor: #f2dede;
  --invalid-color: #a94442;
  --confirm-bcolor: #fff3cd;
  --confirm-color: #856404;
  --borrower-assigned-color: #fff3cd;
  --borrower-placeholder-color: #a1a7af;
  --unmapped-field-color: #ff0000;
  --ribbon-dark-color: #148ecb;
  --grid-border: #eaf0f9;
  --grid-alternative-row-color: #f4f5f7;
  --grid-header-footer-color: #303e67;
  --grid-progress-bar-color: #aad1f9;

  /** tree */
  --tree-item-bg: #f9f9f9;
  --tree-item-color: #0275d8;
  --tree-item-bg-invert: #dddddd;
}

@mixin animate($animation, $duration, $method, $times) {
  animation: $animation $duration $method $times;
}

@mixin keyframes($name) {
  @keyframes #{$name} {
    @content;
  }
}

.align-items-center {
  align-items: center;
}

.cursor {
  cursor: pointer !important;
}

.cursor--help {
  cursor: help !important;
}

.cursor--not-allowed {
  cursor: not-allowed !important;
}


.form-group {
  margin-bottom: 1rem;
}

label {
  opacity: 70%;
}

.no-detail-row .datatable-row-detail {
  display: none !important;
}

.datatable-icon-right {
  text-decoration: none !important;
}

.pager li>a,
.pager li>span {
  border-color: var(--black-color);
}

.modal-w-90 {
  .modal-dialog {
    max-width: 90% !important;
  }
}

.modal-fullscreen.modal-dialog-scrollable {
  height: 100vh !important;
}

.wrapper .aside-container .aside-inner,
.wrapper .aside-container {
  width: 230px;
}

.datatable-header-cell-label {
  color: var(--grid-header-footer-color);
  font-weight: 500;
  vertical-align: middle;
  border-color: var(--grid-border);
}

@keyframes query {
  0% {
    opacity: 1;
    transform: translateX(-50%) scale(0, 1);
  }

  100% {
    opacity: 0;
    transform: translateX(35%) scale(0.3, 1);
  }
}

input[type="radio"][readonly] {
  background: #eee;
  pointer-events: none;
  touch-action: none;
}

input[type="checkbox"][readonly] {
  background: #eee;
  pointer-events: none;
  touch-action: none;
}

.disabled-overlay {
  opacity: 0;
  filter: alpha(opacity=0);
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: block;
  z-index: 2;
  background: transparent;
}

div#advanceProfileOptions-header {
  background-color: #eeeeee;
  cursor: pointer;
}

.ck-editor__editable_inline {
  min-height: 300px;
}

.ck-balloon-panel {
  z-index: 99999 !important;
}

.radio-button-primary {
  &:checked+label {
    background-color: #1761fd;
    color: white;

    &:hover {
      cursor: pointer;
    }
  }
}

.card-header-with-button {
  padding-top: 6px !important;
  padding-bottom: 6px !important;

  &>.card-title {
    margin-top: 0.4em;
  }
}

.spinner-border-xs {
  width: 0.75rem;
  height: 0.75rem;
  border-width: 0.2em;
}



.fieldset-title {
  border-bottom: 1px dashed #e3ebf6;
  text-transform: capitalize;
  letter-spacing: 0.02em;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
  color: #2c3652;
  text-shadow: 0 0 1px rgba(241, 245, 250, 0.1);
  font-family: "Poppins", sans-serif;
  padding: 0 0 12px 0;
}

.custom-control-label {
  padding-top: 6px;
  color: #656d9a;
}

.dark-success {
  color: #3b9c7cf2;
}

.multiselect-dropdown {
  .dropdown-btn {
    border: 1px solid #e3ebf6 !important;
    color: #7081b9 !important;

    .selected-item {
      max-width: unset !important;
    }
  }

  .dropdown-list {
    margin-top: 0px !important;
    box-shadow: 0 0px 2px #959595 !important;
  }
}

.dropdown-last-login {
  padding-left: 24px;
  font-size: 12px;
  font-style: italic;
  cursor: not-allowed;

  &:hover {
    cursor: not-allowed;
  }

  pointer-events: none;
}

a {
  color: #35aaef;

  &:hover {
    cursor: pointer;
  }
}

/* ---- In–editor mention list --------------------------------------------------------------- */

.ck-mentions .mention__item {
  display: block;
}

.ck-mentions .mention__item img {
  border-radius: 100% !important;
  //height: 30px !important;
}

.ck-mentions .mention__item span {
  margin-left: .5em;
}

.ck-mentions .mention__item.ck-on span {
  color: var(--ck-color-base-background);
}

.ck-mentions .mention__item .mention__item__full-name {
  color: hsl(0, 0%, 45%);
}

.ck-mentions .mention__item:hover:not(.ck-on) .mention__item__full-name {
  color: hsl(0, 0%, 40%);
}

.ck-content .mention,
.mention {
  background-color: #e8f5fa !important;
  color: blue !important;
  //border: solid 1px red;
}

.rightnav-drawing-open-sm {
  padding-right: 320px !important;
  transition: padding-right 0.5s
}

.rightnav-drawing-open-md {
  padding-right: 480px !important;
  transition: padding-right 0.5s
}

.rightnav-drawing-open-lg {
  padding-right: 640px !important;
  transition: padding-right 0.5s
}

.rightnav-drawing-open-xlg {
  padding-right: 780px !important;
  transition: padding-right 0.5s
}

.rightnav-drawing-open-xxlg {
  padding-right: 1180px !important;
  transition: padding-right 0.5s
}

.rightnav-drawing-open-xxxlg {
  padding-right: 1560px !important;
  transition: padding-right 0.5s
}

.rightnav-drawing-open-xxxxlg {
  padding-right: 1800px !important;
  transition: padding-right 0.5s
}

.rightnav-drawing-closed {
  padding-right: 0px;
  transition: padding-right 0.5s
}

.tracker-image {
  height: 1px !important;
  width: 1px !important;
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important
}

/* Header fixed to the top of the modal */
.modal-header--sticky {
  position: fixed;
  width: 100%;
  top: 0;
  background-color: inherit;
  /* [1] */
  z-index: 1055;
  /* [2] */
}

/* Footer fixed to the bottom of the modal */
.modal-footer--sticky {
  position: fixed;
  width: 100%;
  background-color: white;
  bottom: 0;
  z-index: 1055;
  /* [2] */
}

.card-footer--sticky {

  position: sticky;
  bottom: 15px;
  width: 100%;

}

.modal-body--scroll {
  max-height: calc(100vh - 170px);
  overflow-y: auto;
}

th.wrap-text>div {
  white-space: break-spaces;
}

.width-200p {
  width: 200px !important;
}

.width-100p {
  width: 100px !important;
}

.min-width-300p {
  min-width: 300px;
  width: 300px;
}

.min-width-150p {
  min-width: 150px;
  width: 150px;
}

.max-width-80p {
  max-width: 80px;
  width: 80px;
}

.max-width-150p {
  max-width: 150px;
  width: 150px;
}

.max-width-250p {
  max-width: 250px;
  width: 250px;
}

.min-width-250p {
  min-width: 250px;
  width: 250px;
}

.min-height-49p {
  min-height: 49px;
  height: 49px;
}

.min-width-80p {
  min-width: 80px;
  width: 80px;
}

.min-width-120p {
  min-width: 120px;
  width: 120px;
}

.max-width-100p {
  max-width: 100px;
  width: 100px;
}

.sort-col-header-text {
  &:hover {
    text-decoration: underline;
  }
}

.toggle-password {
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
}

.p-datatable-wrapper {
  background-color: white;
  border-left: 1px solid #DEE2E6 !important;
  border-right: 1px solid #DEE2E6 !important;

  table {
    width: 100%;
    border-collapse: separate !important;
    /* Don't collapse */
    border-spacing: 0;
  }

  table th {
    /* Apply both top and bottom borders to the <th> */
    border-top: 1px solid #DEE2E6 !important;
    border-bottom: 1px solid #DEE2E6 !important;
    border-right: 1px solid #DEE2E6 !important;
    border-width: 1px 1px 1px 0 !important;
  }

  table td {
    /* For cells, apply the border to one of each side only (right but not left, bottom but not top) */
    border-bottom: 1px solid #DEE2E6 !important;
    border-right: 1px solid #DEE2E6 !important;
    border-width: 0 1px 1px 0 !important;
  }

}

.p-datatable-thead {
  display: table-header-group !important;

  tr {
    display: table-row !important;

    >th {
      display: table-cell !important;
    }
  }
}

.p-datatable-tbody {
  display: table-row-group !important;

  tr {
    display: table-row !important;

    >td {
      display: table-cell !important;
    }
  }
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.125em !important;
}

.text-cell-primary {
  font-weight: 500;
  font-size: .9rem;
}

.text-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: inline-block;
}

.cell-mw100 {
  max-width: 100px;
}

.cell-mw125 {
  max-width: 125px;
}

.cell-mw150 {
  max-width: 150px;
}

.cell-mw175 {
  max-width: 175px;
}

.cell-mw200 {
  max-width: 200px;
}

.cell-mw225 {
  max-width: 225px;
}

.cell-mw250 {
  max-width: 250px;
}

.cell-mw275 {
  max-width: 275px;
}

.cell-mw300 {
  max-width: 300px;
}

.p-datatable-wrapper,
.card,
.popover-body {
  box-shadow: 2px 1px 9px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 2px 1px 9px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 2px 1px 9px 0px rgba(0, 0, 0, 0.15);
}

.accordion-item,
.accordion-button {
  border-radius: 0.5rem;
}

.accordion-item:last-of-type {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}

.accordion-item:first-of-type {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
  border-bottom-right-radius: 0rem !important;
  border-bottom-left-radius: 0rem !important;
}

.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}

.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 0.5rem !important;
  border-bottom-left-radius: 0.5rem !important;
}

.accordion-button:not(.collapsed) {
  border-radius: 0px !important
}

.p-datatable .p-sortable-column .p-sortable-column-icon {
  color: gray;
  margin-left: 0.5rem;
  font-size: 0.70rem;
}

.page-action-bar-item {
  background-color: white;
}

.page-action-bar {
  width: 100%;
  border-top: 1px solid #d3ddeb !important;
  border-bottom: 1px solid #d3ddeb !important;
  background-color: white;
  position: sticky !important;
  top: 53px;
  z-index: 20;
  min-height: 43px;
  margin-bottom: 10px;
}

.page-action-bar-vertical {
  width: 75%;
  border-bottom: 1px solid #d3ddeb !important;
  background-color: white;
  position: sticky !important;
  top: 53px;
  z-index: 20;
  min-height: 43px;
  margin-bottom: 5px;
  margin-left: 8.5%;
}

.page-action-bar-sticky {
  position: sticky !important;
  top: 53px;
  z-index: 21;
}

@media (max-width:768px) {
  .rightnav-drawing-open-md {
    padding-right: 0px !important;
    transition: none
  }

  .rightnav-drawing-open-lg {
    padding-right: 0px !important;
    transition: none
  }

  .rightnav-drawing-open-xlg {
    padding-right: 0px !important;
    transition: none
  }

  .rightnav-drawing-open-xxlg {
    padding-right: 0px !important;
    transition: none
  }

  .rightnav-drawing-open-xxxlg {
    padding-right: 0px !important;
    transition: none
  }

  .rightnav-drawing-open-xxxxlg {
    padding-right: 0px !important;
    transition: none
  }

  .templateLeftSideMobile>.popover-body.popover-content {
    display: flex;
    flex-direction: column;
  }

  .templateLeftSideMobile>.popover-body.popover-content>.btn:first-child {
    border-radius: 5px !important;
    margin-bottom: 0.5rem;
  }

  .templateLeftSideMobile>.popover-body.popover-content>.btn {
    border-radius: 5px !important;
    margin-bottom: 0.5rem;
  }

  .templateLeftSideMobile>.popover-body.popover-content>.btn:last-child {
    border-radius: 5px !important;
    margin-bottom: 0.5rem;
  }

  .templateLeftSideMobile>.popover-body.popover-content>popover-container.loanRatiosPopover {
    left: 1px !important;
    min-width: 90% !important
  }

  .templateLeftSideMobile>.popover-body.popover-content>popover-container.templateFICO {
    left: 1px !important;
    min-width: 90% !important
  }

  .templateLeftSideMobile>.popover-body.popover-content>popover-container.buttonPricingDetailsCard {
    left: 1px !important;
    min-width: 90% !important
  }

  .templateLeftSideMobile>.popover-body.popover-content>popover-container.templateDTI {
    left: 1px !important;
    max-width: 80% !important;
  }

  .templateLeftSideMobile>.popover-body.popover-content>popover-container.templateCharacteristics {
    left: 1px !important;
    min-width: 90% !important;
  }

  .templateLeftSideMobile>.popover-body.popover-content>popover-container.templateFTC {
    left: 1px !important;
    max-width: 80% !important
  }

  .templateLeftSideMobile>.popover-body.popover-content>popover-container>.popover-arrow {
    margin-left: 0px !important;
  }
}

//  .nav.nav-tabs .nav-item.show:focus,
//  .nav.nav-tabs .nav-item.show.active,
//  .nav.nav-tabs .nav-link:focus,
//  .nav.nav-tabs .nav-link.active {
//    font-weight: 500;
//    font-size: .92rem;
//  }

@for $i from 1 through 30 {
  $height: $i * 10;

  .table-row-height--#{$height} {
    height: #{$height}px;
    min-height: #{$height}px;
    max-height: #{$height}px;
  }
}

.special-radio-btn-group {
  .btn-group-left label:first-of-type {
    border-radius: 15px 0 0 15px;
  }

  .btn-group-left label:last-of-type {
    border-radius: 0 15px 15px 0;
  }

  .btn-group-left>label {
    margin: 0;
    color: #535358;
    border: none;
    border-radius: 15px;
  }

  input[type="radio"]:checked+label {
    border-radius: 15px;
    background-color: #f3f8ff;
    color: #3aa3fe;
  }

  .btn-group-left label:not(:first-of-type) {
    border-left: none;
  }

  .btn-group-left label:not(:last-of-type) {
    border-right: none;
  }

  .btn-group-left>label:hover {
    background-color: rgba(23, 97, 253, 0.1);
    border-radius: 15px !important;
  }

  .btn-group-left {
    border: 1px solid #e3ebf6 !important;
    border-radius: 15px;
  }

  .btn-group>.btn:not(.dropdown-toggle),
  .btn-group>.btn-group:not(:last-child)>.btn {
    border-radius: 15px !important;
  }

  .content-style {
    margin-top: -8px;
    margin-bottom: -8px;
  }
}

/* Border Color */
ng-select2.ng-invalid .select2-container {
  border: solid 1px var(--invalid-color);
  background-color: var(--invalid-bcolor);
  border-radius: 4px;
}

ng-select2.ng-invalid .select2-container--classic .select2-selection--multiple,
ng-select2.ng-invalid .select2-container--classic .select2-search--inline .select2-search__field {
  border: none;
  background-color: var(--invalid-bcolor);
}

.custom-ng-select.is-invalid>.ng-select-container {
  border: 1px solid #f5325c !important;
}

.ng-select-container {
  border-radius: 0.5rem !important;
  border: 1px solid #e3ebf6 !important;

  .ng-placeholder {
    top: 7px !important;
  }
}

.ng-select .ng-arrow-wrapper .ng-arrow {
  border-color: none !important;
  border-style: none !important;
  border-width: 0 !important;
}

.ng-select .ng-arrow-wrapper {
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232c3652' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
}

.multiselect-dropdown .dropdown-btn .dropdown-down {
  border-top: 6px solid #999 !important;
  border-left: 6px solid transparent !important;
  border-right: 6px solid transparent !important;
}

.multiselect-dropdown .dropdown-btn .dropdown-up {
  border-bottom: 6px solid #999 !important;
  border-left: 6px solid transparent !important;
  border-right: 6px solid transparent !important;
}

.multiselect-dropdown .dropdown-btn,
.select2-container .select2-selection--single,
.select2-container .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  border: 1px solid #e3ebf6 !important;
  border-radius: 0.5rem !important;
}

.dropdown-btn>span {
  color: #999;
}

ng-multiselect-dropdown {
  .dropdown-multiselect__caret {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232c3652' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right 0.75rem center;
    background-size: 16px 12px;
    height: 35px !important;

    &::before {
      border: none !important;
    }
  }
}

.select2-container--default .select2-selection--single .select2-selection__arrow b {
  border: none !important;
  background-color: transparent;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%232c3652' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.2rem center;
  background-size: 16px 12px;
  height: 20px !important;
  position: absolute;
  width: 20px;
  left: 0 !important;
  margin: 0 !important;
  top: 0 !important;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 20px !important;
  position: absolute !important;
  top: 6px !important;
  right: 0px !important;
  width: 23px !important;
}

ckeditor.is-invalid .ck-editor {
  border: 1px solid #f5325c !important;
}

.ck-content .table table td,
.ck-content .table table th {
  border: none !important;
  min-width: 0 !important;
  padding: 0 !important;
}

ng-multiselect-dropdown.ng-invalid.ng-touched>.multiselect-dropdown .dropdown-btn {
  //border: 1px solid #f5325c !important;
  //border-radius: 5px;
  border-color: #f5325c !important;
}

.generic-error-label {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #f5325c;
}

.ngx-contextmenu>ul.dropdown-menu {
  overflow-y: scroll;
  max-height: 30vh;
}

.owningBorrowerIds-readonly.ng-invalid-required {
  border: none !important;
  background-color: var(--invalid-bcolor) !important;
  border-bottom: solid 2px var(--invalid-color) !important;
}

.navbar-brand {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  margin-right: 0px !important;
  margin-left: 0.5rem !important;
  font-size: 1.01562rem;
  text-decoration: none;
  white-space: nowrap;
}

.rounded-btn {
  border-radius: 15px;
  height: 33.5px;
  margin-top: 3px;
}

.page-action-bar-middle {
  margin-top: 3.5px !important;
}

.page-action-bar-left-margin {
  margin-left: 0.7rem !important;
  margin-right: 1rem !important;
}

.page-action-bar-right-margin {
  margin-right: 0.7rem !important;
  display: inline-flex;
}

.page-action-bar-right button {
  border-radius: 15px;
  height: 33.5px;
  margin-top: 3px;
}

.page-action-bar-middle a.btn {
  border-radius: 15px;
  height: 33.5px;
}

/* Chrome, Safari, Edge, Opera */
input.numeric-without-arrows::-webkit-outer-spin-button,
input.numeric-without-arrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input.numeric-without-arrows[type=number] {
  -moz-appearance: textfield;
}

.widget-editor-wrapper,
.drawer-content-wrapper {
  padding: 15px;
}

.inline-edit-link {
  border-bottom: dashed 1px #0088cc;
}

.widget-center {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 90%;
}

.single-line .angular-editor-textarea {
  overflow-y: hidden !important;
  height: 36px !important;
  min-height: 36px !important;
}

.angular-editor-textarea {

  thead,
  tbody,
  tfoot,
  tr,
  td,
  th {
    border-style: none !important;
  }
}

.active-global-filter {
  background-color: #e2efcb !important;
  border-color: #8bb0fe;
}

.fee-lock-icon-btn {
  min-width: 26px;
}

.fee-icon-unlocked {
  color: orange;
}

.fee-icon-locked {
  color: #a4abc5;
}

.urla-currency {
  .p-inputwrapper {
    border: none;
    background-color: transparent;

    .p-inputnumber {
      border: none;
      background-color: transparent;
      padding: 0;
      height: 25px;
    }

    .p-inputtext {
      border: none;
      background-color: transparent;
      padding: 0;
      color: black;
      width: 100%;
    }

    .p-inputtext:enabled:focus {
      border: none;
      outline: none;
      background-color: transparent;
      box-shadow: none;
    }
  }

  .p-inputwrapper-focus {
    border: none;
    background-color: transparent;
  }
}

.p-inputwrapper[styleclass="loda-input"].is-invalid {
  border: solid 1px #e24c4c;
  border-radius: 0.5rem !important;
}

.p-inputwrapper[styleclass="loda-input"] {
  border: none;
  background-color: transparent;
  width: 100%;

  .p-inputnumber {
    border-radius: 0.5rem !important;
    width: 100%;
  }

  .loda-input {
    .p-inputtext[readonly] {
      background-color: #f1f5fa;
      opacity: 1;
    }

    .p-inputtext[readonly]:enabled:focus {
      background-color: #f1f5fa !important;
      opacity: 1;
    }

    .p-inputtext {
      display: block;
      width: 100%;
      padding: 0.375rem 0.75rem;
      font-size: .8125rem;
      font-weight: 400;
      line-height: 1.5;
      color: #303e67;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #e3ebf6;
      appearance: none;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }

    .p-inputtext:hover {
      border-color: #e3ebf6 !important;
    }

    .p-inputtext:focus:hover {
      border-color: #8bb0fe !important;
    }

    .p-inputtext:enabled:focus {
      color: #303e67;
      background-color: #fff;
      border-color: #8bb0fe;
      outline: 0;
      box-shadow: none;
    }
  }
}

.p-inputwrapper-focus {
  border: none;
  background-color: transparent;
}

.modal-dialog-90pct-of-screen-width {
  .modal-dialog {
    max-width: 90% !important;
  }
}

tr.row-warning>td {
  border-color: #ffffff !important;
  background-color: #efe1b3 !important;
}

tr.row-light-grey>td {
  border-color: #eff1f3 !important;
  background-color: #d4d4d4 !important;
}

.left-sidenav .menu-content {
  padding: 0 !important;
}

.no-click-events {
  pointer-events: none;
}

.inline-editor-container {
  text-decoration: #1761fd7a dashed underline !important;
  width: max-content !important;
  border-color: transparent !important;

  &:hover {
    cursor: pointer !important;
  }
}

.inline-editor-container-readonly {
  width: max-content !important;
  max-width: 100% !important;
  border-color: transparent !important;
  word-wrap: break-word !important;
}

.inline-editor-text-wrapper>.inline-editor-container-readonly {
  display: inline-block !important;
  max-width: 205px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  padding: 0px !important;
}

.inline-editor-text-wrapper>.inline-editor-container {
  display: inline-block !important;
  max-width: 205px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;

}

div.inline-editor.shifted-input {
  width: 245px !important;
  left: -130px;
}

.card-body {
  .row .col-6:first-of-type {
    display: flex;
    align-items: center;
  }
}

.sticky-nav-tabs-open-dialer {
  top: 176px !important
}

.sticky-button-bar {
  position: sticky;
  top: 130px;
  background-color: #f4faff;
  padding: 10px;
  z-index: 14;
}

.sticky-button-bar-vertical {
  top: 95px;
}

.sticky-button-bar-horizontal {
  top: 133px;
}

.sticky-button-bar-vertical.sticky-nav-tabs-open-dialer {
  top: 133px !important
}

.feeSplitPopover {
  left: 0 !important;
  min-width: 360px;

  .popover {
    left: 151px !important;
  }

  .popover-arrow {
    margin-left: 0 !important;
  }
}

.popover {
  max-width: 600px !important;

  table-cell-contact-info {
    .contact-info {
      font-size: .81rem !important;
      margin-bottom: 3px !important;
    }
  }

  .contact-info-address {
    font-size: .81rem !important;
    margin-bottom: 3px !important;
  }

  .credit-scores-popover {
    font-size: .81rem !important;
  }
}

.card-header .btn-sm {
  margin-top: -2px;
  margin-bottom: -2px;
}


@media (max-width: 768px) {
  .col-form-label {
    text-align: left;
  }

  .text-end {
    text-align: left;
  }

  .hide-on-mobile {
    display: none !important;
  }
}

@media (min-width: 769px) {
  .hide-on-non-mobile {
    display: none !important;
  }
}

@media(max-width: 992px) {
  .sticky-button-bar {
    top: 94px;
  }
}

@media (max-width: 567px) {
  .d-xs-flex {
    display: flex !important;
  }

  .d-xs-none {
    display: none !important;
  }

  .w-xs-100 {
    width: 100%;
  }

  .flex-xs-row {
    flex-direction: row !important;
  }

  .flex-xs-column {
    flex-direction: column !important;
  }

  .flex-xs-wrap {
    flex-wrap: wrap !important;
  }

  .justify-content-xs-between {
    justify-content: space-between !important;
  }

  .justify-content-xs-evenly {
    justify-content: space-evenly !important;
  }

  .justify-content-xs-center {
    justify-content: center !important;
  }

  .justify-content-xs-start {
    justify-content: start !important;
  }

  .align-items-xs-center {
    align-items: center !important;
  }

  .text-xs-center {
    text-align: center !important;
    margin-bottom: 2px;
  }

  .h-xs-fit-content {
    height: fit-content !important;
  }

  .mb-xs-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xs-2 {
    margin-bottom: 0.5rem !important;
  }

  .mt-xs-2 {
    margin-top: 0.5rem !important;
  }

  .mx-xs-0 {
    margin-left: 0px !important;
    margin-right: 0px !important;
  }

  .mx-xs-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }

  .ms-xs-0 {
    margin-left: 0px !important;
  }

  .ms-xs-auto {
    margin-left: auto !important;
  }

  .me-xs-0 {
    margin-right: 0px !important;
  }

  .my-xs-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }
}

.mobile-col-selector-popover {
  .popover-body {
    padding: 0px;
  }

  .p-multiselect {
    border: none;
  }

  .p-multiselect-panel {
    top: 0px !important;
    left: 0px !important;
  }
}

.swal2-styled {
  &.swal2-cancel {
    border: 0 !important;
  }

  &.swal2-deny {
    font-size: 1.0625em !important;
  }
}

.quick-apply {
  .invalid-requested-border {
    border: 1px solid #f7c522;
  }

  .invalid-required-border {
    border: 1px solid #a94442;
  }

  .form-select {
    background-size: 16px 12px !important;
  }

  .ng-select-container:not(.ng-has-value) {
    border-color: #f7c522 !important;
    background-size: 0;
    background-color: #fffdf6 !important;
  }

  .invalid-requested {
    border-color: #f7c522;
    background-size: 0;
    background-color: #fffdf6;

    .invalid-feedback {
      display: none !important;
    }

    .form-control {
      background-size: 0;
      background-color: #fffdf6;
      border-color: #f7c522;
    }

    option {
      background-color: #fff;
    }
  }

  .invalid-required:not(ng-select) {
    border-color: #a94442;
    background-size: 0;
    background-color: #f2dede;

    .invalid-feedback {
      display: none !important;
    }

    .form-control {
      background-size: 0;
      background-color: #f2dede;
      border-color: #a94442;
    }

    option {
      background-color: #fff;
    }
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-optgroup {
  color: #000000;
  font-size: 16px;
}

.pricer-form label.form-label {
    margin-bottom: -0.5rem !important;
}

select option.option-group:disabled {
  color: #000;
  font-weight: bold;
}
